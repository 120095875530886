import * as React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Img from '../components/img';

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout
      location={location}
      title={siteTitle}
    >
      <Seo
        title="404: Not Found"
        location={location}
        type="blog-list"
      />
      <Container>
        <Inner>
          <h1>404: Not Found</h1>
          <p>お探しのページは見つかりませんでした。</p>
          <ImgContainer>
            <Link to="/">
              <Img
                alt="404"
                image="common/404.png"
                className="img-fluid"
              />
            </Link>
          </ImgContainer>
        </Inner>
      </Container>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Inner = styled.div`
  text-align: center;
  padding: 24px;
`;

const ImgContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 35%;
    margin: 0 auto;
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;
